<template>
  <div class="page page--terms terms">
    <h1>{{ $t('terms.title') }}</h1>
    <div v-if="$i18n.locale==='nl'">
      <h2>1.Algemeen</h2>
      <p>Onderhavige algemene voorwaarden zijn van toepassing op alle offertes, bestellingen en diensten van de BV Huur
        Een Stuur, al dan niet handelend onder de handelsbenaming Billo, met zetel te 9000 Gent, Wiedauwkaai 98 en met
        ondernemingsnummer 0450.825.019 (hierna: HES), behoudens uitdrukkelijk andersluidend beding en met uitsluiting
        van de voorwaarden van de medecontractant. Als medecontractant wordt eenieder beschouwd die een contractuele
        band heeft met HES en de bestelling betaalt (hierna: Klant). Als Klant wordt tevens eenieder beschouwd die een
        bestelling plaatst, ongeacht of zij de prijs van de daaruitvloeiende opdracht al dan niet dient te betalen.</p>
      <p>Aangetekende brieven dienen steeds gericht te worden aan HES op bovenvernoemd adres. Andere schriftelijke
        communicatie dient gericht te worden aan het mailadres van HES (contact@billo.be).</p>
      <h2>2.Bestellingen en ritten</h2>
      <p>Bestellingen van ritten kunnen per mailbericht, telefoon of via het online reserveringssysteem aangevraagd
        worden. De aangeboden prijzen zijn vrijblijvend tot het moment van effectieve bestelling. Prijzen die aangeboden
        worden door middel van een offerte zijn maar geldig voor een periode van 30 dagen. De prijzen gelden enkel voor
        de opdracht zoals vermeld in de bestelling.</p>
      <p>Aangeboden prijzen zijn steeds afhankelijk van brandstofprijzen, reistijden, etc. zodat deze niet toegepast
        kunnen worden op andere ritten. Indien een korting wordt verleend dan wordt deze korting slechts eenmalig te
        zijn verleend, tenzij anders vermeld. Alle péage of tol is altijd extra te betalen door de Klant, tenzij anders
        vermeld bij de offerte, reservatie of vervoersdocument.</p>
      <p>Er kan tot 48 uur voor aanvang van het event of voor het vertrek van de vlucht, trein, etc. een rit besteld
        worden.</p>
      <p>De bestelling is pas afgerond eens een bevestiging werd verstuurd naar de Klant. De rit die volgt uit een
        bestelling zal maar uitgevoerd worden eens de bestelling werd afgerond.</p>
      <h2>3.Verantwoordelijkheid Klant</h2>
      <p>De Klant is verantwoordelijk voor de correcte ingave en mededeling van alle gegevens zoals:</p>
      <ul class="list">
        <li>het mailadres,</li>
        <li>het vluchtnummer,</li>
        <li>tijdstip van vertrek en aankomst,</li>
        <li>de start en het einde van het event, alsook iedere wijziging hieromtrent,</li>
        <li>het aantal passagiers,</li>
        <li>het aantal tussenstops,</li>
        <li>speciale wensen (zoals vervoer van een rolstoel, een kinderzitje, uitzonderlijke bagage, etc,</li>
        <li>de aanwezigheid van dieren,</li>
        <li>contactgegevens van de passagiers,</li>
        <li>wijzigingen,</li>
        <li>het aantal stukken bagage,</li>
        <li>ieder ander gegeven dat redelijkerwijze relevant kan zijn voor de reservatie en/of de uitvoering van de
          rit.
        </li>
      </ul>
      <p>De Klant is tevens verantwoordelijk voor de vlotte bereikbaarheid van de plaats van ophaling. Enige vertraging
        hierdoor valt ten laste van de Klant. Daarenboven kan HES een extra vergoeding aanrekenen bij gebreke aan
        correcte mededeling van de nodige gegevens.</p>
      <p>Indien HES schade zou lijden doordat de plaats van ophaling niet vlot bereikbaar bleek, zal de Klant HES voor
        de schade volledig vrijwaren.</p>
      <p>Bij ontvangst van het op te halen uur dient de Klant enigszins te controleren of dit een realistisch tijdstip
        is en geen kennelijke vergissing, in welk geval de Klant HES hiervan zo snel mogelijk op de hoogte brengt.</p>
      <p>Wanneer bepaalde wensen/zaken niet werden aangegeven bij de bestelling heeft HES het recht deze bijkomende
        wensen/zaken te weigeren uit te voeren of een bijkomende vergoeding aan te rekenen overeenkomstig de courante
        eenheidsprijzen. Dit is bijvoorbeeld het geval bij extra bagage, een extra passagier, de vraag van een
        kinderstoeltje, etc. </p>
      <p>Bij een wijziging van het uur van het eigenlijke vertrek, vluchtgegevens, reisgegevens, etc. dient de Klant HES
        zo snel mogelijk hiervan op de hoogte te brengen.</p>
      <p>De Klant dient HES tevens op de hoogte te brengen bij enige vertraging of oponthoud door bepaalde
        omstandigheden zoals onder meer een douanecontrole, verloren bagage, etc.</p>
      <p>Er wordt van de Klant ook verwacht dat hij HES zo snel mogelijk contacteert wanneer blijkt dat de chauffeur
        niet aanwezig is op de afgesproken plaats op het afgesproken tijdstip.</p>
      <p>Iedere wijziging en/of correctie alsook nieuwe bestellingen dienen rechtstreeks doorgegeven worden aan HES.
        Indien de Klant deze zaken enkel doorgeeft aan de chauffeur kan het HES niet verweten worden hier geen rekening
        mee te houden.</p>
      <h2>4.Annulering/stopzetting</h2>
      <p>HES heeft het recht de opdracht te annuleren/stop te zetten, met onmiddellijke ingang en zonder
        ingebrekestelling, noch schadevergoeding in geval van staking van betaling of (aanvraag van) het faillissement
        door de Klant of bij vereffening of stopzetting van de activiteiten van de Klant of inden de Klant, ondanks
        schriftelijke ingebrekestelling waarbij een termijn van 7 kalenderdagen in acht wordt genomen, in gebreke blijft
        de verplichting na te komen. HES heeft eveneens steeds het recht de rit te annuleren in geval van overmacht.</p>
      <p>De Klant kan de bestelling schriftelijk annuleren. Annulering kan kosteloos tot 24 uur voor vertrek. Annulering
        is in principe niet mogelijk vanaf 24 uur voor vertrek. In dit laatste geval wordt het volledige bedrag
        aangerekend.</p>
      <p>HES mag, zonder enige schadevergoeding voor de Klant/passagiers, de rit geheel of gedeeltelijk annuleren indien
        er zich voor of tijdens de uitvoering van de rit overmachtssituaties en/of buitengewone omstandigheden voordoen,
        zijnde omstandigheden die HES bij bevestiging van de rit niet kende of kon kennen en die, indien HES ze wel had
        gekend, een geldige reden zouden zijn geweest om de rit niet uit te voeren. Buitengewone omstandigheden zijn
        onder meer vervoer van illegale personen of goederen, onvoorzienbare routewijzigingen, etc.</p>
      <p>Dit artikel en zijn bepalingen zijn van toepassing behoudens enige tegenstrijdige wettelijke dwingende
        bepaling.</p>
      <h2>5.Uitvoering</h2>
      <p>HES streeft er naar de Klant tijdig op te halen en te vervoeren doch is dit afhankelijk van externe factoren
        zoals onder meer extreem weer, sneeuw, ijzel, files, verkeersongevallen, etc. HES doet haar uiterste best zich
        aan te passen aan deze factoren en in de mate van het mogelijke te voorzien. In het kader van de
        verkeersveiligheid kan HES in uitzonderlijke omstandigheden er voor opteren de opdracht niet uit te voeren. In
        dergelijk geval zal het betaalde bedrag terugbetaald worden aan de Klant.</p>
      <p>HES kan niet aansprakelijk gesteld worden voor vergoeding van enige schade die het gevolg is van laattijdigheid
        door externe factoren zoals onder meer extreem weer, sneeuw, ijzel, files, verkeersongevallen, etc.</p>
      <p>HES zal naar best vermogen de passagiers vervoeren binnen de aangegeven tijdspanne. Dit laatste betreft een
        middelenverbintenis.</p>
      <p>HES heeft het recht een passagier, al dan niet op voorhand, te weigeren wanneer deze de verkeersveiligheid in
        het gedrang zou brengen en/of wanneer de passagiers in een gesteldheid verkeert die een ordentelijke rit in de
        weg zou kunnen staan.</p>
      <h2>6.Ophaling en afhaling</h2>
      <p>HES streeft er naar een richtuur van ophaling mee te delen aan de Klant of, desgevallend, aan de passagier
        minstens 2 dagen voorafgaandelijk aan de ophaling zelf. Het concrete uur kan evenwel nog gewijzigd worden
        afhankelijk van de noodwendigheden van de organisatie van HES tot 24 uur voor de ophaling.</p>
      <p>Er wordt geen richtuur meegedeeld wanneer de passagier wordt afgehaald vanaf een luchthaven, station, haven,
        event, etc. Het uur van aankomst of het einduur dienen uiteraard steeds correct te worden meegedeeld.
        Desgevallend streeft HES er naar een chauffeur op tijd, 30 minuten na het tijdstip van de aankomst/beëindiging
        van het event, beschikbaar te stellen. De chauffeur zal hierna 30 minuten wachten. Indien de Klant HES tijdig
        verwittigt, zal de chauffeur langer wachten maar zal de extra wachttijd aangerekend worden aan de gebruikelijke
        eenheidsprijzen.</p>
      <p>Een chauffeur van HES maakt zich bij afhaling steeds bekend door middel van een bordje waarop het logo van
        HES/BILLO te herkennen is alsook de naam van de Klant of, desgevallend, de aangeduide passagier. De Klant wordt
        per sms-bericht door HES in kennis gesteld van de naam en het telefoonnummer van de chauffeur zodat de Klant de
        chauffeur steeds rechtstreeks kan contacteren, moest dit nodig zijn.</p>
      <p>Om de ritten zo vlot mogelijk te laten verlopen en omdat de chauffeurs ook tijdig bij de volgende passagiers
        zouden kunnen zijn, wordt er van de passagiers verwacht dat zij klaarstaan op het afgesproken uur. Indien dit
        niet het geval zou zijn, kan de chauffeur langer wachten, in zoverre de planning dit toelaat. Er zal dan een
        wachttijd aangerekend worden aan de gebruikelijke eenheidsprijzen. Wanneer de planning het niet zou toelaten om
        de chauffeur te laten wachten op de passagiers kan HES het te laat komen van de passagiers beschouwen als een
        annulering die vergoed zal dienen te worden overeenkomstig de algemene voorwaarden.</p>
      <h2>7.Aansprakelijkheid HES</h2>
      <p>De aansprakelijkheid van HES kan geen aanleiding geven tot enige schadevergoeding, behalve in geval van
        opzettelijke of zware fout of bedrog van HES of haar aangestelden. </p>
      <p>Ten aanzien van Klanten die geen medecontractant zijn, kan HES niet rechtstreeks door deze aangesproken worden,
        behoudens enige dwingende wettelijke bepaling. Desgevallend zal deze klant zich dienen te richten tot de
        initiële opdrachtgever.</p>
      <p>De aansprakelijkheid van HES wordt steeds beperkt tot maximaal de waarde zoals vermeld op de bestelling met een
        maximum van € 2.500,00. HES kan nooit aansprakelijk gesteld worden voor indirecte schade, zoals maar niet
        beperkt tot, gederfde inkomsten, winsten, verlies van een klant, of eender welke gevolgschade, etc. Dit
        impliceert dat HES bijvoorbeeld bij het missen van een vlucht geen schade zal vergoeden die kan bestaan uit het
        betalen van een hotel, het boeken van een nieuwe (aansluitings)vlucht, etc.</p>
      <h2>8.Overmacht</h2>
      <p>In het geval van overmacht is elke partij bevrijd en niet gehouden tot nakoming van enige verbintenis jegens de
        wederpartij. Onder overmacht wordt verstaan de situatie waarin de uitvoering van de overeenkomst door één der
        partijen geheel of gedeeltelijk, al dan niet tijdelijk verhinderd wordt door omstandigheden buiten de wil van
        die partij. Zonder limitatief te willen zijn, wordt als geval van overmacht beschouwd: technische storingen aan
        het vervoermiddel die niet aan enige fout of nalatigheid van HES te wijten is, diefstal van het vervoermiddel,
        extreme weersomstandigheden die afbreuk doen aan de verkeersveiligheid, stakingen, pandemie, etc.</p>
      <h2>9.Gedrag passagiers</h2>
      <p>Iedere passagier dient de verkeersregels in acht te nemen. Dit impliceert tevens dat dieren, uitgezonderd
        begeleidingshonden, veilig vervoerd kunnen worden door middel van een bench en/of dierentas.</p>
      <p>Iedere passagier dient zich te onthouden van beschadigingen en verontreiniging van de wagen, het overmatige
        gebruik van alcohol, het transporteren en/of gebruiken van verboden verdovende middelen, roken, de chauffeur te
        verhinderen van zijn taken uit te voeren, etc.</p>
      <p>Bij enige inbreuk op dit artikel is HES gerechtigd op een schadevergoeding forfaitair begroot op een bedrag van
        50% van de prijs van de opdracht met een minimum van € 150,00 onverminderd het recht van HES om een hogere
        vergoeding te vorderen wanneer de werkelijke schade groter zou zijn. HES behoudt zich tevens het recht voor om
        desgevallend te weigeren de rit (verder) uit te voeren.</p>
      <p>De Klant maakt zich sterk deze schadevergoeding te betalen, alsook HES te vrijwaren voor eventuele boetes die
        haar worden opgelegd.</p>
      <p>Er wordt verwacht dat de Klant de passagiers in kennis stelt van de algemene voorwaarden van HES, waaronder
        uiteraard begrepen de specifieke regels ten opzichte van de passagiers, die toch wel vanzelfsprekend zijn.</p>
      <h2>10.Betaling</h2>
      <p>De facturen zijn betaalbaar ten laatste op de vervaldag op het adres van HES, behoudens andersluidende
        afspraken. Wanneer een factuur niet tijdig betaald wordt, is van rechtswege en zonder enige ingebrekestelling,
        een verwijlinterest verschuldigd van 10%, alsook een forfaitaire schadevergoeding van 15% met een minimum van €
        125,00. Dit beding sluit geenszins aanvullende schadevergoedingen uit. Laattijdige betalingen worden eerst
        aangerekend op de verhogingen en dan pas op de hoofdsom.</p>
      <p>Protest tegen een factuur dient binnen de 8 dagen na ontvangst ervan per aangetekend schrijven te gebeuren op
        het adres van de maatschappelijke zetel van HES.</p>
      <h2>11.Geschillen</h2>
      <p>De ongeldigheid van een (gedeelte van een) bepaling heeft geen invloed op de geldigheid van de overige (
        gedeeltes van de) bepalingen.</p>
      <p>De rechtbanken van het arrondissement waar de maatschappelijke zetel van HES zich bevindt, zijn exclusief
        bevoegd om kennis te nemen van ieder geschil, behoudens enige dwingende wettelijke bepaling.</p>
      <p>Het Belgisch recht is van toepassing.</p>
    </div>
    <div v-else-if="$i18n.locale==='fr'">
      <h2>1. Généralités</h2>
      <p>Les présentes conditions générales s’appliquent à toutes les offres, commandes et services de la SRL Huur Een Stuur, qu’ils soient traités ou non sous la dénomination commerciale Billo, dont le siège social est établi à 9000 Gand, Wiedauwkaai 98 et enregistrée sous le numéro d’entreprise 0450.825.019 (ci-après dénommée : HES), sauf stipulation contraire expresse, et à l’exclusion des conditions du cocontractant. Chaque cocontractant est réputé avoir un lien contractuel avec HES et payer la commande (ci-après : Client). Chaque Client est également réputé placer une commande, indépendamment de si le prix de la mission qui en découle doit être payé ou non.</p>
      <p>Les lettres recommandées doivent toujours être adressées à HES à l’adresse susmentionnée. Les autres communications par écrit doivent être adressées à l’adresse e-mail d’HES (contact@billo.be).</p>
      <h2>2. Commandes et trajets</h2>
      <p>Les commandes de trajets peuvent être effectuées par e-mail, par téléphone ou via le système de réservations en ligne. Les prix proposés sont sans engagement jusqu’au moment de la commande effective. Les prix qui sont proposés par le biais d’une offre sont uniquement valables pour une période de 30 jours. Les prix sont valables uniquement pour la mission qui est indiquée dans la commande.</p>
      <p>Les prix proposés dépendent toujours du prix du carburant, des durées de voyage, etc., de manière à ce que ceux-ci ne puissent pas être appliqués à d’autres trajets. Si une réduction est octroyée, celle-ci n’est accordée qu’une seule fois, sauf indication contraire. Tous les frais de péage ou de douane doivent être payés en sus par le Client, sauf indication contraire dans l’offre, la réservation ou les documents de transport.</p>
      <p>Un trajet peut être réservé au plus tard 48 heures avant le début de l’événement ou avant le départ d’un vol, train, etc.</p>
      <p>La commande est seulement définitive après qu’une confirmation a été envoyée au Client. Le trajet qui est associé à une commande sera uniquement exécuté après que la commande est finalisée.</p>
      <h2>3. Responsabilité du Client</h2>
      <p>Le Client est responsable de la transmission et de la communication correctes de toutes les données nécessaires telles que :</p>
      <ul class="list">
        <li>l’adresse e-mail, </li>
        <li>le numéro de vol, </li>
        <li>l’heure de départ et d’arrivée, </li>
        <li>le début et la fin de l’événement, ainsi que toute modification y associée,</li>
        <li>le nombre de passagers,</li>
        <li>le nombre d’arrêts,</li>
        <li>les demandes spéciales (comme le transport d’un fauteuil roulant, d’un siège pour enfant, de bagages spéciaux, etc.),</li>
        <li>la présence d’animaux,</li>
        <li> les données de contact des passagers,</li>
        <li>les modifications,</li>
        <li>le nombre de bagages individuels,</li>
        <li>toute donnée qui pourrait être raisonnablement pertinente pour la réservation et/ou l’exécution du trajet.</li>
      </ul>
      <p>Le Client est également responsable de la facilité d’accès du lieu de rendez-vous. Tout retard y associé est imputé au Client. En outre, HES est autorisée à facturer une indemnité supplémentaire en cas d’absence de communication adéquate des données nécessaires.</p>
      <p>Si HES subit un dommage provoqué par le manque d’accès du lieu de rendez-vous, le Client s’engage à indemniser entièrement les dégâts causés à HES.</p>
      <p>À la réception de l’heure de rendez-vous, le Client s’engage à vérifier s’il s’agit d’une heure raisonnable et qu’il ne s’agit pas d’une erreur, auquel cas le Client s’engage à prévenir HES dans les plus brefs délais.</p>
      <p>Lorsque des demandes/requêtes spécifiques n’ont pas été mentionnées lors de la prise de commande, HES a le droit de refuser d’exécuter ces demandes/requêtes supplémentaires ou de facturer une indemnité supplémentaire conformément aux prix unitaires habituels. Cette situation se produit par exemple en cas de bagage supplémentaire, de passager supplémentaire, de demande d’une chaise pour enfant, etc. </p>
      <p>En cas de modification de l’heure de départ réelle, des données de vol, des données de voyages, etc., le Client s’engage à prévenir HES dans les plus brefs délais.</p>
      <p>Le Client s’engage à prévenir HES de tout retard causé par des circonstances spécifiques incluant notamment un contrôle de douane, une perte de bagage, etc.</p>
      <p>Il est également attendu du Client qu’il contacte HES le plus rapidement possible lorsqu’il s’avère que le chauffeur n’est pas présent sur le lieu convenu et à la date et l’heure convenues. </p>
      <p>Toute modification et/ou correction ainsi que les nouvelles commandes doivent être directement transmises à HES. Si le Client transmet uniquement ces informations au chauffeur, HES ne peut en aucun cas être tenue pour responsable de ne pas avoir pris celles-ci en compte.</p>
      <h2>4. Annulation/interruption</h2>
      <p>HES a le droit d’annuler/interrompre la mission avec effet immédiat et sans mise en demeure, et sans indemnisation en cas de cessation des paiements ou de (demande de) faillite du Client ou en cas de liquidation ou d’arrêt des activités du Client ou si le Client, malgré la mise en demeure écrite qui mentionne la prise en compte d’un délai de 7 jours calendriers, continue de ne pas honorer ses engagements. HES a également le droit d’annuler le trajet à tout moment en cas de force majeure.</p>
      <p>Le Client est autorisé à annuler la commande par écrit. L’annulation peut être effectuée gratuitement jusqu’à 24 heures avant le départ. L’annulation n’est en principe pas possible à partir de 24 heures avant le départ. Dans ce dernier cas, l’intégralité du montant est facturée.</p>
      <p>HES a le droit d’annuler le trajet en tout ou en partie, sans possibilité de réclamation d’une indemnisation de la part du/des Client/passagers, si un cas de force majeure et/ou des circonstances inhabituelles se présentent avant ou pendant l’exécution du trajet, à condition que ces circonstances n’étaient pas connues ou n’auraient pas pu être connues par HES au moment de la confirmation du trajet et qui, si HES en avait été informée, auraient constitué une raison valable pour ne pas exécuter le trajet. Les circonstances exceptionnelles comprennent notamment le transport illégal de personnes ou de marchandises, des modifications d’itinéraire imprévisibles, etc.</p>
      <p>Cet article et ses dispositions s’appliquent sous réserve d’une quelconque disposition contraire légalement contraignante.</p>
      <h2>5. Exécution</h2>
      <p>HES s’engage à aller chercher le Client et à le conduire à temps, sauf si ceci dépend de facteurs externes tels que des conditions météorologiques extrêmes, de la neige, du verglas, des embouteillages, des accidents de la route, etc. HES fait de son mieux pour s’adapter à ces facteurs et pour prévoir ceux-ci dans la mesure du possible. Dans le cadre de la sécurité routière, HES est autorisée à choisir de ne pas exécuter la mission en cas de circonstances exceptionnelles. Dans une situation de ce type, le montant payé sera remboursé au Client.</p>
      <p>HES ne peut pas être tenue pour responsable de l’indemnisation d’un quelconque dommage causé par un retard lié à des facteurs externes comme des conditions météorologiques extrêmes, de la neige, du verglas, des embouteillages, des accidents de la route, etc.</p>
      <p>HES s’engage à mettre tout en œuvre pour assurer le transport des passagers dans le délai imparti. Ce dernier élément est associé à une obligation de moyens. </p>
      <p>HES a le droit, à l’avance ou non, de refuser un passager si celui-ci est susceptible de compromettre la sécurité routière et/ou si les passagers se trouvent dans un état qui pourrait nuire à un transport convenable.</p>
      <h2>6. Collecte des passagers</h2>
      <p>HES s’engage à partager un objectif d’heure de rendez-vous avec le Client ou, le cas échéant, au passager au moins 2 jours avant le rendez-vous lui-même. L’heure précise peut toutefois encore être modifiée en fonction des nécessités d’organisation d’HES jusqu’à 24 heures avant le rendez-vous. </p>
      <p>Aucun objectif d’heure n’est partagé lorsque le passager à aller chercher se trouve dans un aéroport, une gare, un port, un événement, etc. L’heure d’arrivée ou l’heure de fin doivent bien entendu toujours être communiquées correctement. Au besoin, HES s’engage à mettre à disposition un chauffeur dans les temps, 30 minutes après l’heure d’arrivée/fin de l’événement. Le chauffeur attendra 30 minutes à partir de cette heure. Si le Client prévient HES à temps, le chauffeur peut attendre plus longtemps, mais le temps supplémentaire sera facturé aux prix unitaires habituels.</p>
      <p>Lors de la collecte, un chauffeur d’HES se manifeste toujours au moyen d’un panneau avec un logo d’HES/BILLO ainsi que le nom du Client ou, le cas échéant, du passager concerné. Le Client est informé par un sms envoyé par HES du nom et du numéro de téléphone du chauffeur de sorte que le Client puisse toujours contacter directement le chauffeur en cas de besoin.</p>
      <p>Afin que les trajets se déroulent le plus facilement possible et dans le but que les chauffeurs puissent également arriver à temps pour les passagers suivants, il est attendu des passagers qu’ils soient prêts à l’heure prévue. Si ce n’est pas le cas, le chauffeur peut attendre plus longtemps, dans la mesure où le planning lui permet de rester. Un temps d’attente sera dès lors comptabilisé aux prix unitaires habituels. Si le planning ne permet pas au chauffeur d’attendre les passagers, HES peut considérer que le retard des passagers est synonyme d’annulation qui devra être indemnisée conformément aux conditions générales.</p>
      <h2>7. Responsabilité d’HES</h2>
      <p>La responsabilité d’HES ne peut pas engendrer d’indemnisation, sauf en cas de dol ou de faute grave ou de fraude de la part d’HES ou de ses préposés. </p>
      <p>HES ne peut pas être directement tenue pour responsable vis-à-vis des Clients qui ne sont pas des cocontractants, sous réserve d’une quelconque disposition légale impérative. Le cas échéant, ce Client doit s’adresser au donneur d’ordre initial.</p>
      <p>La responsabilité d’HES se limite aussi toujours à un montant maximal équivalent à celui indiqué sur la commande, avec un maximum de 2 500,00 €. HES ne peut jamais être tenue pour responsable de dommages indirects, incluant notamment, mais non exclusivement, le manque à gagner, la perte de profit, les pertes d’un client, ou un quelconque dommage indirect, etc. Ceci implique que HES n’est par exemple pas tenue d’indemniser un vol raté par le paiement d’un hôtel, la réservation d’un nouveau vol (de correspondance), etc.</p>
      <h2>8. Force majeure</h2>
      <p>En cas de force majeure, toute partie est libre de ne pas respecter ses engagements vis-à-vis de l’autre partie. La force majeure est une situation dans laquelle l’exécution du contrat par l’une des parties est entravée, en tout ou en partie, de manière temporaire ou définitive, par des circonstances indépendantes de la volonté de cette partie. Les éléments suivants sont considérés, sans s’y limiter, comme des cas de force majeure : perturbations techniques au moyen de transport qui ne peuvent pas être imputées à une faute ou à une négligence d’HES, vol du moyen de transport, conditions météorologiques extrêmes qui nuisent à la sécurité routière, grèves, pandémie, etc.</p>
      <h2>9. Comportement des passagers</h2>
      <p>Chaque passager doit respecter les règles de circulation. Ceci implique également que les animaux, à l’exception des chiens d’aveugle, peuvent être transportés en toute sécurité au moyen d’une cage et/ou d’un sac de transport pour animaux.</p>
      <p>Chaque passager s’interdit d’endommager et de salir le véhicule, de consommer de l’alcool de manière excessive, de transporter et/ou d’utiliser des stupéfiants illégaux, de fumer, de gêner le chauffeur dans l’exécution de ses tâches, etc.</p>
      <p>En cas d’infraction à cet article, HES est autorisée à réclamer une indemnisation forfaitaire équivalente à 50 % du prix de la mission, avec un minimum de 150,00 €, sans préjudice du droit d’HES de réclamer une indemnisation plus élevée si le dommage réel est plus important. HES se réserve également le droit dans une telle situation de refuser d’exécuter ou d’interrompre le trajet.</p>
      <p>Le Client s’engage à payer cette indemnisation et de protéger HES contre des amendes éventuelles qui lui seraient imposées. </p>
      <p>Il est attendu du Client que les passagers aient pris connaissance des conditions générales d’HES, qui incluent bien entendu les règles spécifiques que les passagers doivent respecter, qui sont néanmoins évidentes.</p>
      <h2>10. Paiement</h2>
      <p>Les factures sont payables au plus tard à la date d’échéance à l’adresse d’HES, sauf convention contraire. Si une facture n’est pas payée à temps, un intérêt de retard de 10 % est dû de plein droit et sans mise en demeure, ainsi qu’une indemnité forfaitaire de 15 % avec un minimum de 125,00 €. Cette disposition n’exclut en aucun cas des indemnités complémentaires. Les retards de paiement seront d’abord imputés sur les majorations et ensuite seulement sur le montant principal. </p>
      <p>Toute contestation d’une facture doit être faite par lettre recommandée à l’adresse du siège social d’HES dans les 8 jours suivant sa réception.</p>
      <h2>11. Litiges</h2>
      <p>L’invalidité d’une (partie d’une) disposition n’affecte pas la validité des autres (parties des) dispositions. </p>
      <p>Les tribunaux de l’arrondissement où se trouve le siège social d’HES ont une compétence exclusive pour prendre connaissance de tout litige, sous réserve d’une quelconque disposition légale impérative.</p>
      <p>Le droit belge s’applique.</p>
    </div>
    <div v-else>
      <h2>1. General</h2>
      <p>These general terms and conditions apply to all quotes, orders and services offered by BV Huur Een Stuur, which may also trade under the trade name of Billo, whose registered office is situate at 9000 Ghent, Wiedauwkaai 98 and whose company registration number is 0450.825.019 (hereinafter: HES), unless expressly provided for otherwise and excluding the conditions of the co-contractor. A co-contractor is considered to be anyone that enters into contractual relations with HES and pays for the order (hereinafter: the Customer). A Customer is considered to be anyone that places an order, irrespective of whether they are obliged to pay for the job arising from it.</p>
      <p>Registered letters must be addressed to HES at the address above. Other written communication must be sent to the HES email address (contact@billo.be).</p>
      <h2>2. Orders and trips</h2>
      <p>Orders for trips can be made by email, telephone or the online reservation system. The prices quoted are free of obligation until the order is placed. Prices given by means of a quote are valid for a period of 30 days only. The prices apply only to the job described in the order. </p>
      <p>The prices quoted are based on fuel prices, travel times, etc., and are not transferable to other trips. If a discount is given it is given on one occasion only, unless stated otherwise. Tolls and charges are always payable by the Customer, unless stated otherwise in the quote, reservation or transport document.</p>
      <p>A trip may be ordered up to 48 hours before the event or the departure of the flight, train, etc.</p>
      <p>The order is not complete until the confirmation has been sent to the Customer. The trip ensuing from an order will not be performed until the order has been completed.</p>
      <h2>3. Customer’s responsibilities</h2>
      <p>The Customer is responsible for entering the details correctly and providing accurate details such as:</p>
      <ul class="list">
        <li>email address,</li>
        <li>flight number,</li>
        <li>departure and arrival times,</li>
        <li>start and end time of the event, as well as any changes,</li>
        <li>number of passengers,</li>
        <li>number of pick-up or other stops,</li>
        <li>special requirements (such as transport of a wheelchair, child's seat, unusual luggage, etc.),</li>
        <li>transport of animals,</li>
        <li>passenger contact details,</li>
        <li>changes,</li>
        <li>number of baggage items,</li>
        <li>any other details that may be of relevance to the booking and/or performance of the trip</li>
      </ul>
      <p>The Customer is also responsible for ensuring that the pick-up point is easily accessible. The customer shall be liable for any delays caused as a result. In addition, HES may charge an extra fee if the information provided is not accurate.</p>
      <p>If HES sustains damage because the pick-up point was not readily accessible, the Customer shall fully warrant HES for the damage.</p>
      <p>When given the pick-up time the Customer must ascertain that this is a realistic time and not an obvious error, in which case the Customer must notify HES as soon as possible.</p>
      <p>If certain requirements/services are not specified in the order, HES reserves the right to refuse to perform these additional requirements/services or to charge extra for them at the current unit prices. This would apply, for example, in the case of additional baggage, an extra passenger, a request for a highchair, etc. </p>
      <p>If changes are made to the time of departure, flight details, travel details, etc. the Customer must notify HES as soon as possible.</p>
      <p>The Customer must also notify HES of any delay or hold-up caused by customs checks, lost luggage, etc.</p>
      <p>The Customer is expected to contact HES as soon as possible if the driver does not attend at the place and time agreed. </p>
      <p>All changes and/or corrections and new orders must be passed on to HES directly. If the Customer passes this information to the driver only, HES cannot be expected to take it into account.</p>
      <h2>4. Cancellation/stoppage</h2>
      <p>HES has the right to cancel /stop the job with immediate effect and without giving notice or paying compensation if the Customer ceases payments or is declared bankrupt (or files for bankruptcy) or goes into administration or ceases trading or if, despite a written reminder giving notice of 7 days, the Customer remains in default with regard to the fulfilment of its obligation. HES also reserves the right to the cancel the trip in a case of force majeure.</p>
      <p>The Customer may cancel the order in writing. Orders may be cancelled free of charge up to 24 hours prior to departure. Orders cannot be cancelled within 24 hours of departure. In such cases the price will be charged full.</p>
      <p>HES may, without compensating the Customer/passengers, cancel the trip in part or in full if, before or during performance, a situation of force majeure and/or extraordinary circumstances arise, being circumstances of which HES was not or could not have been aware when the trip was confirmed and which, had HES been aware of them, would have given a genuine reason for not performing the trip. Extraordinary circumstances include the transportation of illegal immigrants or goods, unforeseen changes of route, etc.</p>
      <p>This article and its provisions apply subject to any mandatory statutory provisions to the contrary.</p>
      <h2>5. Performance</h2>
      <p>HES will endeavour to collect and transport the Customer on time, but this is dependent on external factors such as extreme weather, snow, ice, traffic congestion, road traffic accidents, etc. HES will do its utmost to adapt and where possible respond to these factors. For road safety reasons HES may in exceptional circumstances opt not to perform the contract. In such a case the Customer will be refunded.</p>
      <p>HES cannot be held liable for the compensation of losses resulting from late arrival as the result of external factors such as extreme weather, snow, ice, traffic congestion, road traffic accidents, etc.</p>
      <p>HES will endeavour to the best of its ability to transport the passengers within the specified time period. The latter is an obligation of means. </p>
      <p>HES reserves the right to refuse a passenger, or to do so in advance, if that passenger presents a possible threat to road safety and/or the passengers are in a state of mind that might prevent an orderly trip.</p>
      <h2>6. Pick-up and drop-off</h2>
      <p>HES endeavours to provide the Customer or, as the case may be, the passenger, with an estimated pick-up time at least 2 days prior to the pick-up. The actual time may change up to 24 hours in advance of the pick-up, depending on the organisational requirements of HES. </p>
      <p>An estimated time will not be given if the passenger is to be picked up from an airport, station, port, event, etc. The exact time or arrival or end time must of course always be given. If necessary HES will endeavour to provide a driver within 30 minutes of the time of arrival/end of the event. The driver will wait for a further 30 minutes. Provided the Customer notifies HES in good time, the driver will wait longer, but the extra time will be charged at the current unit rate.</p>
      <p>An HES driver will always identify himself on a pick-up by holding a sign bearing the HES/BILLO logo and the Customer's name, or as the case may be, the designated passenger. The Customer will receive a text from HES containing the name and telephone number of the driver, with which the Customer can contact the driver directly should the need arise.</p>
      <p>To help the trips run efficiently and allow drivers the time to get to the next passenger, passengers are expected to be ready and waiting at the arranged time. If they are not, the drivers can wait, but only if their schedule allows. In that case, the waiting time will be charged at the usual unit rate. If a driver’s schedule prevents him from waiting for the passengers, HES is entitled to consider the passengers’ late arrival as a cancellation, payable in accordance with the general terms and conditions.</p>
      <h2>7. Liabilities of HES</h2>
      <p>HES cannot be held liable for the reimbursement of any losses, save in a case of serious misconduct or deception on the part of HES or its appointees. </p>
      <p>There can be no direct recourse to HES in respect of the above, in the case of Customers who are not co-contractors, unless by mandatory statutory provision. Wherever appropriate these Customers will need to approach the initial client.</p>
      <p>The maximum liability of HES is always limited to the value stated in the order, to a maximum of €2,500.00. HES cannot be held liable for indirect losses such as but not limited to lost income, profits, loss of custom, consequential damage of any other kind, etc. This implies that when a flight is missed, for example, HES will not compensate losses such as the cost of a hotel, the reservation of a new (connecting) flight, etc.</p>
      <h2>8. Force Majeure</h2>
      <p>In the case of force majeure each party is released from its obligations towards the other party. By force majeure is understood a situation in which the performance of the contract by either party is partially or fully, temporarily or otherwise, prevented by circumstances beyond that party's control. Cases of force majeure are understood to be, although not exhaustively: technical issues with transport not attributable to the fault of negligence of HES, theft of the transport, extreme weather conditions affecting road safety, strikes, pandemics, etc.</p>
      <h2>9. Behaviour of passengers</h2>
      <p>Every passenger is required to follow the highway code. This also implies that animals, guide dogs excluded, may be safely transported by means of a bench and/or pet travel bag.</p>
      <p>Passengers must refrain from damaging or soiling the car, excessive consumption of alcohol, the transportation and/or use of illegal drugs, smoking, preventing the driver from performing his tasks, etc.</p>
      <p>In the case of any activity contrary to this clause HES is entitled to fixed compensation equivalent to 50% of the price of the order with a minimum of € 150.00 notwithstanding the right of HES to claim a higher amount in compensation if the actual loss is greater. HES also reserves the right to refuse (to complete) the trip if needs be.</p>
      <p>The Customer agrees to pay the compensation and indemnify HES for any fines imposed. </p>
      <p>It is expected that the Customer will inform the passengers of the terms and conditions set by HES, which do of course include the rules specific to passengers, which are straightforward and clear.</p>
      <h2>10. Payment</h2>
      <p>Invoices are payable no later than their due date at the address of HES, unless agreed otherwise. If an invoice is not paid on time, late payment interest at 10% shall be due automatically and without further notice, in addition to a compensation charge of 15% with a minimum of € 125.00. This provision does not exclude any additional compensation. Late payments are calculated first on the increases and only then on the principal. </p>
      <p>If an invoice is contested notification should be made within 8 days of receipt by registered mail to the registered office of HES.</p>
      <h2>11. Disputes</h2>
      <p>The invalidity of all or part of a provision shall have no effect on the validity of the remaining provisions and parts thereof. </p>
      <p>The courts of the judicial district in which the registered offices of HES are located shall have sole competence to hear any dispute, unless other mandatory statutory provisions apply. </p>
      <p>Belgian law is applicable.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",

  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>